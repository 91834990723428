import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const RetirableCardContactlessLarge = () => (
  <StaticImage
    src="./assets/retirable-card-contactless-large.webp"
    alt="Retirable contactless card"
  />
);

export const RetirableCardContactlessSmall = () => (
  <StaticImage
    src="./assets/retirable-card-contactless-small.webp"
    alt="Retirable contactless card"
  />
);
