import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { graphql } from 'gatsby';
import { HowItWorksLargeFragment as HowItWorksLargeFragmentType } from 'graphqlTypes';
import { WrapperLarge } from 'page-components/about-us/shared/WrapperLarge';
import React, { FC } from 'react';

import { heading } from './content';
import { Steps } from './Steps';

export const HowItWorksLargeFragment = graphql`
  fragment HowItWorksLarge on Query {
    ...Steps
  }
`;

interface HowItWorksLargeProps {
  data: HowItWorksLargeFragmentType;
}

export const HowItWorksLarge: FC<HowItWorksLargeProps> = ({ data }) => (
  <WrapperLarge maxWidth={1568} spacing={97}>
    <ResponsiveHeading variant={2} marginBottom={72}>
      {heading}
    </ResponsiveHeading>
    <Steps data={data} />
  </WrapperLarge>
);
