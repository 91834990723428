import { ArrowUpAndToTheRight } from 'component-library/components/icons';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { graphql } from 'gatsby';
import { ApyBadgeFragment as ApyBadgeFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import { getCurrentInterestRateDetails } from './base';

export const ApyBadgeFragment = graphql`
  fragment ApyBadge on Query {
    ...GetCurrentInterestRateDetails
  }
`;

interface OuterWrapperProps {
  hideBoxShadow?: boolean;
}

const OuterWrapper = styled(Flex)<OuterWrapperProps>`
  padding: 23px 26px;
  background: #ffffff;

  border: 1px solid #26313b;
  border-radius: 24px;
  box-shadow: ${({ hideBoxShadow = false }) =>
    hideBoxShadow ? 'unset' : ' -5px 5px'};
`;

const StyledArrowUpAndToTheRight = styled(ArrowUpAndToTheRight)`
  margin-left: 25px;
  margin-right: 50px;
`;

interface ApyBadgeProps extends OuterWrapperProps {
  data: ApyBadgeFragmentType;
}

export const ApyBadge: FC<ApyBadgeProps> = ({ data, ...props }) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);
  return (
    <OuterWrapper column {...props}>
      <Body variant={4}>Current APY</Body>
      <Flex alignItems="center">
        <Heading variant={4}>{formattedApy}</Heading>
        <StyledArrowUpAndToTheRight />
      </Flex>
    </OuterWrapper>
  );
};
