import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { ManAndWomanWalkingOutside } from 'component-library/images/ManAndWomanWalkingOutside';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { SpendingQuery } from 'graphqlTypes';
import {
  BottomCta,
  FeaturesSection,
  Footer,
  ThreeStepSection,
} from 'page-components/shared';
import { Hero, HowItWorks } from 'page-components/spending';
import {
  companionHeading,
  getCompanionSteps,
} from 'page-components/spending/companion/utils';
import {
  featuresHeading,
  featuresSections,
  topFeatureSection,
} from 'page-components/spending/features/utils';
import React, { FC } from 'react';

interface SpendingProps {
  data: SpendingQuery;
}

const Spending: FC<SpendingProps> = ({ data }) => (
  <Layout>
    <Navigation />
    <PageWrapper background={colors.greenLight}>
      <Hero data={data} />
      <ThreeStepSection
        heading={companionHeading}
        image={<ManAndWomanWalkingOutside />}
        steps={getCompanionSteps(data)}
      />
      <FeaturesSection
        heading={featuresHeading}
        topSection={topFeatureSection}
        sections={featuresSections}
      />
      <HowItWorks data={data} />
      <BottomCta heading="Make your cash go further in retirement." />
      <Footer data={data} displayDepositAccountDetails />
    </PageWrapper>
  </Layout>
);

export default Spending;

export const Head = () => (
  <SEO
    title="Debit Card | Retirable"
    description="Spend tax-efficiently and directly from your savings while earning more on your cash with our high-interest account."
    imagePath="spending-card-featured.png"
  />
);

export const query = graphql`
  query Spending {
    ...Hero
    ...Companion
    ...HowItWorks
    ...DepositAccountAgreement
  }
`;
