import { ApyBadge } from 'common/interest-rate-components/ApyBadge';
import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { SpendingCardHeroLarge } from 'component-library/images/SpendingCardHero';
import { graphql } from 'gatsby';
import { HeroLargeFragment as HeroLargeFragmentType } from 'graphqlTypes';
import { WrapperLarge } from 'page-components/about-us/shared/WrapperLarge';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const ApyBadgeWrapper = styled(ShowOn).attrs({
  screens: [Screen.Xxl, Screen.Xl],
})``;

export const HeroLargeFragment = graphql`
  fragment HeroLarge on Query {
    ...ApyBadge
  }
`;

const Text = styled(Flex)`
  flex-basis: 50%;
`;

const HeadingAndBody = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 12% 40px;
`;

const Image = styled(Flex)`
  flex-basis: 50%;
`;

interface HeroLargeProps {
  data: HeroLargeFragmentType;
}

export const HeroLarge: FC<HeroLargeProps> = ({ data }) => (
  <WrapperLarge maxWidth={1728} spacing={0} padding="0">
    <Flex>
      <Text column>
        <HeadingAndBody>
          <ResponsiveHeading variant={1} marginBottom={24}>
            {heading}
          </ResponsiveHeading>
          <ResponsiveBody variant={1} marginBottom={48}>
            {body}
          </ResponsiveBody>
          <Flex alignItems="center" justifyContent="space-between">
            <GetStartedButton />
            <ApyBadgeWrapper>
              <ApyBadge data={data} />
            </ApyBadgeWrapper>
          </Flex>
        </HeadingAndBody>
      </Text>
      <Image>
        <SpendingCardHeroLarge />
      </Image>
    </Flex>
  </WrapperLarge>
);
