import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const SpendingCardHeroLarge = () => (
  <StaticImage
    src="./assets/spending-card-hero-large.webp"
    alt="Spending card hero"
  />
);

export const SpendingCardHeroSmall = () => (
  <StaticImage
    src="./assets/spending-card-hero-small.webp"
    alt="Spending card hero"
  />
);
