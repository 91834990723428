import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { IconName } from 'component-library/components/icons/Icon';
import { graphql } from 'gatsby';
import { CompanionFragment as CompanionFragmentTypes } from 'graphqlTypes';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';

export const CompanionFragment = graphql`
  fragment Companion on Query {
    ...GetCurrentInterestRateDetails
  }
`;

export const companionHeading =
  'The spending companion to your retirement plan.';

export const getCompanionSteps = (data: CompanionFragmentTypes) => [
  {
    iconName: IconName.StayOnTrack,
    heading: 'Stay on track',
    body:
      "Your advisor will only put what's safe to spend in your account each month so you can spend confidently.",
  },
  {
    iconName: IconName.BoostIncome,
    heading: `Earn ${getCurrentInterestRateDetails(data).formattedApy}${
      FOOTNOTE_REFERENCES.asterisk
    } on your cash`,
    body:
      'Monitor your savings in a high-yield account with one of the best APYs in the market.',
  },
  {
    iconName: IconName.KeepMoreMoney,
    heading: 'Ideal for saving or spending',
    body:
      'Unite your income streams into one account for an easier retirement or build an emergency fund before retiring.',
  },
];
