import { graphql } from 'gatsby';
import { HowItWorksFragment as HowItWorksFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';

import { HowItWorksLarge } from './HowItWorksLarge';
import { HowItWorksSmall } from './HowItWorksSmall';

export const HowItWorksFragment = graphql`
  fragment HowItWorks on Query {
    ...HowItWorksSmall
    ...HowItWorksLarge
  }
`;

interface HowItWorksProps {
  data: HowItWorksFragmentType;
}

export const HowItWorks: FC<HowItWorksProps> = ({ data }) => (
  <>
    <HowItWorksSmall data={data} />
    <HowItWorksLarge data={data} />
  </>
);
