import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const PersonalPlanWithSpendCardLarge = () => (
  <StaticImage
    src="./assets/personal-plan-with-spend-card-large.webp"
    alt="Retirable spend card with income bar chart"
  />
);

export const PersonalPlanWithSpendCardSmall = () => (
  <StaticImage
    src="./assets/personal-plan-with-spend-card-small.webp"
    alt="Retirable spend card with income bar chart"
  />
);
