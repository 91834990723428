import {
  CoupleAndPieChartLarge,
  CoupleAndPieChartSmall,
} from 'component-library/images/CoupleAndPieChart';
import {
  PersonalPlanWithSpendCardLarge,
  PersonalPlanWithSpendCardSmall,
} from 'component-library/images/PersonalPlanWithSpendCard';
import {
  RetirableCardContactlessLarge,
  RetirableCardContactlessSmall,
} from 'component-library/images/RetirableCardContactless';
import {
  SpendBarChartLarge,
  SpendBarChartSmall,
} from 'component-library/images/SpendBarChart';
import { device } from 'component-library/styles/device';
import React from 'react';
import styled from 'styled-components';

export const featuresHeading = 'See what sets it apart';

const ControlSpendingWrapper = styled.div`
  ${`@media ${device.lg}`} {
    margin-bottom: -40px;
  }

  ${`@media ${device.xxl}`} {
    margin-top: -120px;
  }
`;

export const topFeatureSection = {
  heading: 'Take control of your spending.',
  body:
    'No more waiting for fund transfers. Simply use your card to spend what you need from your retirement savings each month, using your spending plan as your guide.',
  imageLarge: (
    <ControlSpendingWrapper>
      <PersonalPlanWithSpendCardLarge />
    </ControlSpendingWrapper>
  ),
  imageSmall: <PersonalPlanWithSpendCardSmall />,
};

export const featuresSections = [
  {
    heading: 'Simplify your income streams.',
    body:
      'Tax efficiently spend Social Security, pensions, and other income streams, all through your debit card.',
    imageLarge: <CoupleAndPieChartLarge />,
    imageSmall: <CoupleAndPieChartSmall />,
  },
  {
    heading: 'Monitor your spending.',
    body:
      'Get a better understanding of your monthly cash flows to help your advisor personalize your plan.',
    imageLarge: <SpendBarChartLarge />,
    imageSmall: <SpendBarChartSmall />,
  },
  {
    heading: 'Make your cash go further.',
    body:
      "With inflation on everyone's mind, we're proud to offer our clients high interest rates on their cash balances. Retirees can earn some protection against inflation while pre-retirees can build emergency savings faster than before.",
    imageLarge: <RetirableCardContactlessLarge />,
    imageSmall: <RetirableCardContactlessSmall />,
  },
];
