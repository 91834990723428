import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { graphql } from 'gatsby';
import { HowItWorksSmallFragment as HowItWorksSmallFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import { heading } from './content';
import { Steps } from './Steps';

export const HowItWorksSmallFragment = graphql`
  fragment HowItWorksSmall on Query {
    ...Steps
  }
`;

const HowItWorksWrapper = styled(ShowOn)`
  padding: 80px 20px;
`;

interface HowItWorksSmallProps {
  data: HowItWorksSmallFragmentType;
}

export const HowItWorksSmall: FC<HowItWorksSmallProps> = ({ data }) => (
  <HowItWorksWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading variant={4} marginBottom={40}>
      {heading}
    </Heading>
    <Steps data={data} />
  </HowItWorksWrapper>
);
