import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { Badge } from 'component-library/components/badge/Badge';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { device } from 'component-library/styles/device';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  GetStepsFragment as GetStepsFragmentTypes,
  StepsFragment as StepsFragmentType,
} from 'graphqlTypes';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';
import React, { FC } from 'react';
import styled from 'styled-components';

export const StepsFragment = graphql`
  fragment Steps on Query {
    ...GetSteps
  }
`;

export enum StepVariant {
  StayOnTrack = 'StayOnTrack',
  BoostIncome = 'BoostIncome',
  KeepMoreMoney = 'KeepMoreMoney',
}

export const GetStepsFragment = graphql`
  fragment GetSteps on Query {
    ...GetCurrentInterestRateDetails
  }
`;

const getSteps = (data: GetStepsFragmentTypes) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);
  return [
    {
      image: <StaticImage src="../images/how-it-works-step-1.webp" alt="" />,
      heading: 'Add Your Card',
      body:
        'Add your free card** anytime to work alongside your retirement plan and make spending easier to track.',
    },
    {
      image: <StaticImage src="../images/how-it-works-step-2.webp" alt="" />,
      heading: 'Spend Your Way',
      body:
        'Every month your advisor will automatically load your account with your safe to spend amount.',
    },
    {
      image: <StaticImage src="../images/how-it-works-step-3.webp" alt="" />,
      heading: 'Retire With More',
      body: `Easily generate lifestyle insights on your spending while earning ${formattedApy} APY${FOOTNOTE_REFERENCES.asterisk} on your cash balance.`,
    },
  ];
};

const Image = styled.div`
  max-width: 400px;
  margin-bottom: 40px;

  ${`@media ${device.lg}`} {
    margin-bottom: 54px;
  }
`;

const StepsWrapper = styled(Flex)`
  gap: 64px;

  ${`@media ${device.lg}`} {
    flex-direction: row;
    gap: 40px;
  }
`;

interface StepsProps {
  data: StepsFragmentType;
}

export const Steps: FC<StepsProps> = ({ data }) => (
  <StepsWrapper column>
    {getSteps(data).map(({ image, heading, body }, idx) => (
      <Flex column key={idx}>
        <Image>{image}</Image>
        <Badge marginBottom={32}>Step {idx + 1}</Badge>
        <Subheading medium marginBottom={12} variant={1}>
          {heading}
        </Subheading>
        <Body variant={3}>{body}</Body>
      </Flex>
    ))}
  </StepsWrapper>
);
